<template>
  <div>
    <b-row>
      <b-col xl="3" lg="12" md="12" sm="12" cols="12" class="mb-25">
        <b-card class="card-custom text-center h-100">
          <b-avatar :src="userPhoto" size="8rem"></b-avatar>

          <h4 class="font-weight-bold my-2">{{ list.firstname }} {{ list.lastname }}</h4>

          <b-badge size="lg" class="bg-light-warning mb-5 mt-2"> Active</b-badge>

          <b-button variant="primary" class="font-weight-bold py-3 px-6 mb-3 mt-4 btn-block" style="cursor: pointer">
            Send Message
          </b-button>
          <b-button variant="warning" class="font-weight-bold py-3 px-6 btn-block" style="cursor: pointer">
            Send Email
          </b-button>
        </b-card>
      </b-col>
      <b-col xl="9" lg="12" md="12" sm="12" cols="12" class="mb-25">
        <!--begin::Form-->
        <b-card footer-tag="footer" footer-class="text-right">
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group class="font-weight-bolder" label="Personal Info">
                <div class="d-flex justify-content-between">
                  <b-form-input class="mr-1" :value="list.firstname" placeholder="First Name" readonly></b-form-input>
                  <b-form-input class="ml-1" :value="list.lastname" placeholder="Last Name" readonly></b-form-input>
                </div>
              </b-form-group>

              <!-- <b-form-group>
                <b-form-input v-if="list.company" :value="list.company.name" placeholder="Company Name"></b-form-input>
              </b-form-group> -->

              <b-form-group>
                <b-form-input :value="list.email" placeholder="E-Mail" readonly></b-form-input>
              </b-form-group>
              <b-form-group class="font-weight-bolder" label="Account Info">
                <select
                  :disabled="loginUserRole !== 'superadmin'"
                  class="form-control"
                  id="exampleFormControlSelect1"
                  @change="setRole($event)"
                >
                  <option v-for="(r, i) in role" v-bind:key="i" :value="r._id" :selected="list.role._id == r._id">
                    {{ r.name }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
            <!-- NOTE : Addresses -->
            <h5 class="font-weight-bolder mt-11 mb-9">Address</h5>
            <p style="font-weight: 400; margin: 8px">Your dont have Addresses</p>

            <b-form-group v-if="list.addresses[0]">
              <div class="d-flex">
                <b-form-input placeholder="Street" v-model="list.addresses[0].street" class="mr-2"></b-form-input>
                <b-form-input
                  v-model="list.addresses[0].houseNumber"
                  placeholder="House No"
                  class="col-3"
                ></b-form-input>
              </div>
            </b-form-group>
            <b-form-group v-if="list.addresses[0]">
              <div class="d-flex">
                <b-form-input v-model="list.addresses[0].zipCode" placeholder="ZIP Code" class="col-3"></b-form-input>
                <b-form-input v-model="list.addresses[0].city" placeholder="City" class="ml-2"></b-form-input>
              </div>
            </b-form-group>
            <!-- <b-col cols="12" lg="6">
              <b-form-group label="Address" class="font-weight-bolder">
                <p style="font-weight: 400; margin: 8px">Your dont have Addresses</p>
                <div class="d-flex">
                  <b-form-input
                    v-if="list.addresses[0]"
                    :value="list.addresses[0].street"
                    placeholder="Street"
                    class="mr-2"
                    readonly
                  ></b-form-input>
                  <b-form-input
                    v-if="list.addresses[0]"
                    :value="list.addresses[0].houseNumber"
                    placeholder="House No"
                    class="col-3"
                    readonly
                  ></b-form-input>
                </div>
              </b-form-group>
              <b-form-group>
                <div class="d-flex">
                  <b-form-input
                    v-if="list.addresses[0]"
                    :value="list.addresses[0].zipCode"
                    placeholder="ZIP Code"
                    class="col-3"
                    readonly
                  ></b-form-input>
                  <b-form-input
                    v-if="list.addresses[0]"
                    :value="list.addresses[0].city"
                    placeholder="City"
                    class="ml-2"
                    readonly
                  ></b-form-input>
                </div>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-if="list.addresses[0]"
                  :value="list.addresses[0].country"
                  placeholder="Country"
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col> -->
          </b-row>

          <template #footer>
            <b-button variant="primary" @click="changeRole" class="font-weight-bold px-10"> Save Changes </b-button>
          </template>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card class="card card-custom">
          <!--begin::Body-->

          <div class="tab-content overflow-auto">
            <!--begin::Table-->
            <div class="table-responsive">
              <div class="card-toolbar mb-3">
                <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                  <li class="nav-item">
                    <a
                      class="nav-link py-2 px-4 font-weight-bold"
                      :class="{ active: this.show === 'vehicles' }"
                      data-toggle="tab"
                      href="#kt_tab_pane_3_1"
                      @click="show = 'vehicles'"
                    >
                      Vehicles
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-2 px-4 font-weight-bold"
                      data-toggle="tab"
                      :class="{ active: this.show === 'events' }"
                      href="#kt_tab_pane_2_1"
                      @click="show = 'events'"
                    >
                      Events
                    </a>
                  </li>
                </ul>
              </div>
              <template v-if="show == 'vehicles'">
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr>
                      <th class="table-mw-100 pl-7">Image</th>
                      <th class="table-mw-100 text-center">Licence Plate</th>
                      <th class="table-mw-100 text-center">Brand</th>
                      <th class="table-mw-100 text-center">Model</th>
                      <th class="table-mw-100 text-center">Year of Manufacture</th>
                      <th class="table-mw-100 text-center">Engine</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, i) in list.vehicles">
                      <tr v-bind:key="i" class="text-center">
                        <td class="pl-0 pt-8 table-mw-150">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-130 symbol-light mr-4">
                              <span class="symbol-label">
                                <img :src="vehiclePhoto(item.photoUrl[0].default)" class="h-100 w-100 align-self-end" />
                              </span>
                            </div>
                            <div>
                              <span class="text-dark-75 font-weight-bolder mb-1 font-size-sm">{{ item.name }}</span>
                            </div>
                          </div>
                        </td>
                        <td class="table-mw-100">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.licencePlate
                          }}</span>
                          <span class="text-muted font-weight-bold">{{ item.status }}</span>
                        </td>
                        <td class="table-mw-100">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.brand.value
                          }}</span>
                        </td>
                        <td class="table-mw-100">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.model.value
                          }}</span>
                        </td>
                        <td class="table-mw-100 pr-0">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.productionPeriod.value
                          }}</span>
                        </td>
                        <td class="pr-0 table-mw-100">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.motorEngine.value
                          }}</span>
                        </td>
                        <!-- <td class="pr-0 table-mw-100">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">
                            {{ list.createdAt.substr(0, 10) }} {{ list.createdAt.slice(11, 19) }}
                          </span>
                        </td> -->
                      </tr>
                    </template>
                  </tbody>
                </table>

                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  aria-controls="my-table"
                  align="center"
                  class="mt-10"
                ></b-pagination>
              </template>
              <template v-else>
                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr>
                      <th class="pl-0 pt-8"></th>
                      <th class="table-mw-150">Event Image</th>
                      <th class="table-mw-100" @click="sort('name')">
                        Event Name
                        <i class="fas fa-sort"></i>
                      </th>
                      <th class="table-mw-100" @click="sort('startDateTime')">Start Date<i class="fas fa-sort"></i></th>
                      <th class="table-mw-100" @click="sort('endDateTime')">End Date<i class="fas fa-sort"></i></th>
                      <th class="table-mw-100">Max. Participants</th>
                      <th class="table-mw-100" @click="sort('name')">Status<i class="fas fa-sort"></i></th>
                      <th class="table-mw-100" @click="sort('createdAt')">
                        Created At
                        <i class="fas fa-sort"></i>
                      </th>
                      <th class="table-mw-100"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="forceUpdate">
                      <sc-events-table
                        @update="onStep1Update"
                        v-for="(event, i) in events"
                        :key="i"
                        :name="event.name"
                        :startDate="event.startDateTime"
                        :endDate="event.endDateTime"
                        :participants="event.participantCount"
                        :status="event.status.name"
                        :createdAt="event.createdAt"
                        :id="event._id"
                        :item="event"
                      />
                      <!-- <tr v-bind:key="i" class="text-center" v-for="(item, i) in events">
                        <td class="pl-0 pt-8">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-130 symbol-light mr-4">
                              <span class="symbol-label">
                                <img :src="item.img" class="h-75 align-self-end" alt="" />
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.name }}</span>
                          <span class="text-muted font-weight-bold">{{ item.status.name }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            new Date(item.createdAt).toLocaleString()
                          }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            new Date(item.startDateTime).toLocaleString()
                          }}</span>
                        </td>
                        <td class="pr-0">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.participantCount
                          }}</span>
                        </td>
                        <td class="pr-0">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
                            item.car_brand
                          }}</span>
                        </td>
                        <td class="pr-0">
                          <modal :showPropsModal="modalShow" :eventDetail="item" id="modal-1" ref="childComponent" />
                          <b-button class="btn btn-light-success w-100" @click="openModal(i)">
                            <i class="fa fa-eye"></i>Detail</b-button
                          >
                          <b-button class="btn btn-light-danger mt-5 w-100" @click="deleteEvent()">
                            <i class="fas fa-trash fa-1x mr-1"></i>Delete</b-button
                          >
                        </td>
                      </tr> -->
                    </template>
                  </tbody>
                </table>

                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  aria-controls="my-table"
                  align="center"
                  class="mt-10"
                ></b-pagination>
              </template>
            </div>
            <!--end::Table-->
          </div>

          <!--end::Body-->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import Swal from 'sweetalert2'
import scEventsTable from '../../content/widgets/advance-table/sc-events-table.vue'
import EventServices from '../../../core/services/Event'
import { base_url } from '../../../const'

export default {
  components: { scEventsTable },
  name: 'user-detail',
  data() {
    return {
      forceUpdate: true,
      filter: 'name',
      modalVisible: true,
      currentSort: true,
      modalShow: false,
      currentPage: 1,
      perPage: 8,
      role: [],
      paramUserId: '',
      show: 'vehicles',
      list: {},
      vehicles: [],
      selectedRole: '',
      loginUserId: '',
      loginUserRole: '',
      events: [],
      userPhoto: '',
    }
  },
  mounted() {
    this.paramUserId = this.$route.params.id
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'User' }])
    this.userdetail()
    this.roles()
    this.getLoginUserRole()
  },
  methods: {
    sort: function (filter) {
      this.currentSort = !this.currentSort
      this.forceUpdate = false

      let modifier = 1
      if (this.currentSort === true) modifier = -1

      this.events.sort(function (a, b) {
        if (a[filter] === null || b[filter] === null) {
          return -1 * modifier
        }

        const nameA = a[filter].toUpperCase() // ignore upper and lowercase
        const nameB = b[filter].toUpperCase() // ignore upper and lowercase

        if (nameA < nameB) {
          return -1 * modifier
        }
        if (nameA > nameB) {
          return 1 * modifier
        }

        // names must be equal
        return 0
      })
      this.$nextTick(() => {
        // Add the component back in
        this.forceUpdate = true
      })
    },
    async onStep1Update(newData) {
      await EventServices.eventDelete(newData).then(({ data }) => {
        if (data.status === 200) {
          this.forceUpdate = false
          const newEventsData = this.events.filter(v => v._id != newData.eventId)
          this.events = newEventsData
          this.$nextTick(() => {
            // Add the component back in
            this.forceUpdate = true
          })
        }
      })
    },
    userdetail() {
      ApiService.setHeader()
      ApiService.get('users/' + this.paramUserId).then(({ data }) => {
        this.userPhoto = `${base_url}/uploads/default/${data.photoUrl}`
        this.list = data
      })
      ApiService.post('/events/user-events-by-status', { userId: this.paramUserId, userEventStatus: 2 }).then(
        ({ data }) => {
          console.log(`data`, data)
          this.events = data
        }
      )
    },
    roles() {
      ApiService.setHeader()
      ApiService.get('users/roles').then(({ data }) => {
        data.forEach(element => {
          this.role.push({
            _id: element._id,
            name: element.name,
          })
        })
      })
    },
    getLoginUserRole() {
      const currentUser = localStorage.getItem('scooble_user')
      const parseData = JSON.parse(currentUser)
      this.loginUserId = parseData._id
      this.loginUserRole = parseData.role.name
    },
    setRole(event) {
      this.selectedRole = event.target.value
    },
    changeRole() {
      ApiService.setHeader()
      ApiService.put('/users/roles/user-role-change', {
        userId: this.paramUserId,
        roleId: this.selectedRole,
        superAdminId: this.loginUserId,
      }).then(() => {
        console.log('Success.')
        Swal.fire('Changed!', '', 'success')
      })
    },
    vehiclePhoto(photoUrl) {
      return `${base_url}/uploads/default/${photoUrl}`
    },
  },
  computed: {
    dataToShow() {
      if (this.show === 'vehicles') return this.vehicles
      if (this.show === 'events') return this.events

      return this.event
    },
  },
}
</script>
<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
.mb-25 {
  margin-bottom: 25px !important;
}
</style>
