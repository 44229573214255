import ApiService from '../api.service'

const { get } = ApiService

const MapServices = {
  getMapDetail(fileName) {
    return get(`/event/maps/${fileName}`)
  },
}
export default MapServices
