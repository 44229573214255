<template>
  <div v-if="path.length > 0">
    <gmap-map :center="centerPoint" :zoom="13" style="width: 100%; height: 400px">
      <gmap-polyline :path="path" ref="polyline"> </gmap-polyline>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: 'sc-map-detail',
  props: ['kmlData', 'center'],
  data() {
    return {
      centerPoint: {},
      edited: null,
      path: [],
      mvcPath: null,
      errorMessage: null,
      polylineGeojson: '',
    }
  },
  mounted() {
    const newPath = []
    this.centerPoint = { lat: this.center[1], lng: this.center[0] }
    for (let i = 0; i < this.kmlData.length; i++) {
      const element = this.kmlData[i]
      newPath.push({ lat: element[1], lng: element[0] })
    }
    this.path = newPath
  },
}
</script>

<style>
</style>