<template>
  <!--begin::Advance Table Widget 9-->
  <!--begin::Table-->
  <tr class="" style="border-bottom: 1px solid #e8eaf1">
    <td class="pl-0 pt-8">
      <div class="d-flex align-items-center justify-content-center">
        <div class="symbol symbol-50 symbol-light">
          <i class="fas fa-crown text-primary"></i>
        </div>
      </div>
    </td>
    <td class="pl-0 pt-8 table-mw-150" style="min-width: 150px">
      <div class="d-flex align-items-center">
        <div class="symbol symbol-100 symbol-light mr-4">
          <span class="symbol-label">
            <img :src="photoUrl" class="h-100 align-self-end" alt="" />
          </span>
        </div>
      </div>
    </td>
    <td style="max-width: 125px">
      <span
        class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6"
        style="display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 125px"
        >{{ name }}</span
      >
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
        new Date(startDate).toLocaleString()
      }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
        new Date(endDate).toLocaleString()
      }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ participants }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ status }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
        new Date(createdAt).toLocaleString()
      }}</span>
    </td>
    <td class="pr-0 text-right table-mw-100">
      <!-- <button class="btn btn-light-success font-weight-bolder font-size-sm mt-4" @click="showModal">
          Open Modal!
        </button> -->
      <!-- <router-link class="btn btn-light-success font-weight-bolder font-size-sm mt-4" to="/events/new">
          <i class="fas fa-pencil-alt fa-1x mr-1"></i>Edit</router-link
        > -->
      <modal :showPropsModal="modalShow" :eventDetail="item" id="modal-1" ref="childComponent" />
      <b-button class="btn btn-light-success w-100" @click="openModal()"> <i class="fa fa-eye"></i>Detail</b-button>
      <!-- <b-button class="btn btn-light-success w-100" @click="openModal(item)"> <i class="fa fa-eye"></i>Detail</b-button> -->
      <!-- <b-button class="btn btn-light-info font-weight-bolder font-size-sm ml-3 mt-4">
        <i class="far fa-copy"></i>Duplicate</b-button
      > -->
      <b-button class="btn btn-light-danger mt-5 w-100" @click="deleteEvent()">
        <i class="fas fa-trash fa-1x mr-1"></i>Delete</b-button
      >
    </td>
  </tr>
</template>

<script>
import Swal from 'sweetalert2'
import Modal from '../Modal.vue'
import { base_url } from '../../../../const'

export default {
  components: { Modal },
  name: 'sc-events-table',
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    participants: {
      type: Number,
    },
    status: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
  mounted() {
    this.setPhoto()
  },
  data() {
    return {
      currentPage: 1,
      perPage: 8,
      eventDetail: [],
      modalShow: false,
      photoUrl: '',
    }
  },
  methods: {
    // editClick(item) {
    //   console.log('edit click', item)
    //   this.eventDetail = item
    //   this.$refs['modal-1'].show()
    // },
    // modalChange() {
    //   console.log('object')
    //   if (this.modalShow) {
    //     this.modalShow = this.modalShow
    //   } else {
    //     this.modalShow = !this.modalShow
    //   }
    //   console.log(`this.modalShow`, this.modalShow)
    // },
    // showModal() {
    //   this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
    // },
    setPhoto() {
      console.log(`item`, this.item)
      if (this.item.cover.default) {
        console.log('object')
        this.photoUrl = `${base_url}/uploads/default/${this.item.cover.default}`
      }
    },
    openModal() {
      //access the childComponent via ref
      //access its children and use the correct index for
      //your targeted boostrap vue modal
      //use the show property which is the boostrap show method

      this.$refs.childComponent.$children[0].show()

      //sometimes it helps to console log the ref to see all
      //available properties of ref element and its children
    },
    async deleteEvent() {
      const eventId = this.item._id
      //const userId = this.$store.state.auth.user._id
      const userDetail = localStorage.getItem('scooble_user')
      const userId = JSON.parse(userDetail)._id
      Swal.fire({
        title: 'Sure?',
        text: 'Do you really want to delete this event? The already registered participants will automatically receive a notification.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#181C32',
        cancelButtonColor: '#c3c3c3',
        confirmButtonText: 'Yes, delete',
      }).then(result => {
        console.log(`result`, result)
        if (result.isConfirmed) {
          this.$emit('update', { eventId, userId })
          Swal.fire('Deleted!', '', 'success')
        }
      })
    },
  },
}
</script>
