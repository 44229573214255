<template>
  <b-modal
    hide-backdrop
    content-class="shadow"
    ref="modal-1"
    id="modal-1"
    title="Event Details"
    scrollable
    hide-footer
    size="lg"
  >
    <b-card footer-tag="footer" footer-class="text-right">
      <!-- NOTE: User Detail Start -->
      <b-button v-b-toggle.user-details block class="mb-5" variant="secondary">Organizer Details</b-button>
      <b-collapse id="user-details">
        <b-form-group class="text-muted mx-100">
          <b-card footer-tag="footer" footer-class="text-right">
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group class="font-weight-bolder" label="Personal Info">
                  <div class="d-flex justify-content-between">
                    <b-form-input
                      class="mr-1"
                      :value="item.createdUser.firstname"
                      placeholder="First Name"
                      readonly
                    ></b-form-input>
                    <b-form-input
                      class="ml-1"
                      :value="item.createdUser.lastname"
                      placeholder="Last Name"
                      readonly
                    ></b-form-input>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-form-input :value="item.createdUser.email" placeholder="E-Mail" readonly></b-form-input>
                </b-form-group>
                <b-form-group style="display: flex; justify-content: center; align-items: center">
                  <b-avatar :src="userPhoto" size="8rem"></b-avatar>
                </b-form-group>
              </b-col>
              <!-- NOTE : Addresses -->
              <b-col cols="12" lg="6">
                <b-form-group label="Address" class="font-weight-bolder">
                  <div class="d-flex">
                    <b-form-input
                      v-if="item.createdUser.addresses[0]"
                      :value="item.createdUser.addresses[0].street"
                      placeholder="Street"
                      class="mr-2"
                      readonly
                    ></b-form-input>
                    <b-form-input
                      v-if="item.createdUser.addresses[0]"
                      :value="item.createdUser.addresses[0].houseNumber"
                      placeholder="House No"
                      class="col-3"
                      readonly
                    ></b-form-input>
                  </div>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex">
                    <b-form-input
                      v-if="item.createdUser.addresses[0]"
                      :value="item.createdUser.addresses[0].zipCode"
                      placeholder="ZIP Code"
                      class="col-3"
                      readonly
                    ></b-form-input>
                    <b-form-input
                      v-if="item.createdUser.addresses[0]"
                      :value="item.createdUser.addresses[0].city"
                      placeholder="City"
                      class="ml-2"
                      readonly
                    ></b-form-input>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-if="item.createdUser.addresses[0]"
                    :value="item.createdUser.addresses[0].country"
                    placeholder="Country"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-collapse>
      <!-- NOTE: User Detail End-->
      <b-form-group class="text-muted" label="Event Name">
        <b-form-input :value="item.name" placeholder="Event Name" readonly></b-form-input>
      </b-form-group>
      <b-form-group class="text-muted" label="Event Desc">
        <b-form-textarea
          v-model="item.description"
          class="mb-5"
          placeholder="Description"
          rows="3"
          max-rows="10"
          readonly
        ></b-form-textarea>
      </b-form-group>
      <b-form-group class="text-muted" label="Event Date">
        <b-form-input
          :value="`${new Date(item.startDateTime).toLocaleString()} - ${new Date(item.endDateTime).toLocaleString()}`"
          placeholder="Event Name"
          readonly
        ></b-form-input>
      </b-form-group>

      <!-- NOTE: Participant Begin-->
      <b-form-group class="text-muted" label="Participants">
        <b-form-input :value="item.participants.length" placeholder="Participants" readonly></b-form-input>
      </b-form-group>
      <b-form-group class="text-muted" label="Max. participants">
        <b-form-input :value="item.participantCount" placeholder="Max. participants" readonly></b-form-input>
      </b-form-group>
      <!-- NOTE: Participant End-->

      <sc-map-detail class="mb-5" :kmlData="kmlData" :center="center" />

      <!-- NOTE: Cover Start-->
      <b-button v-b-toggle.cover block class="mb-5" variant="secondary">Cover</b-button>
      <b-collapse id="cover">
        <b-form-group class="text-muted mx-100">
          <b-form-input class="mb-5" :value="item.cover.title" placeholder="Event Name" readonly></b-form-input>
          <b-img center :src="coverPhoto" style="max-width: 300px; max-height: 300px"></b-img>
        </b-form-group>
      </b-collapse>
      <!-- NOTE: Cover End-->
      <!-- NOTE: Stopovers Start-->
      <b-button v-b-toggle.my-collapse block class="mb-5" variant="secondary">Route</b-button>
      <b-collapse id="my-collapse">
        <b-form-group
          class="text-muted mx-100"
          v-for="(item, i) in item.stopovers"
          :key="i"
          :time="item.time"
          :value="item.value"
        >
          <b-form-input
            class="mb-5"
            :value="`${new Date(item.time).toLocaleString()}`"
            placeholder="Event Name"
            readonly
          ></b-form-input>
          <b-form-textarea
            class="mb-7"
            v-model="item.value"
            placeholder="Description"
            rows="3"
            max-rows="10"
            readonly
          ></b-form-textarea>
        </b-form-group>
      </b-collapse>
      <!-- NOTE: Stopovers End-->

      <!-- NOTE: MoreDetail Start -->
      <b-button v-b-toggle.more-details block class="mb-5" variant="secondary">More Details</b-button>
      <b-collapse id="more-details">
        <b-form-group
          class="text-muted mx-100"
          v-for="(item, i) in item.elements"
          :key="i"
          :headline="item.headline"
          :subline="item.subline"
        >
          <p class="mb-5" :value="item.headline" placeholder="HeadLine">
            {{ item.headline }}
          </p>
          <div v-if="item.default" class="symbol symbol-250 symbol-light mb-5" id="app">
            <span class="symbol-label" style="width: 250px; height: 250px">
              <img
                :src="`${url}/uploads/default/${item.default}`"
                style="flex: 1"
                class="h-100 align-self-end"
                alt=""
              />
            </span>
          </div>
          <b-form-input class="mb-5" :value="item.subline" placeholder="subLine" readonly></b-form-input>
          <b-form-textarea
            v-model="item.description"
            class="mb-5"
            placeholder="Description"
            rows="3"
            max-rows="10"
            readonly
          ></b-form-textarea>

          <b-button block variant="primary" v-if="item.buttonText">{{ item.buttonText }}</b-button>
        </b-form-group>
      </b-collapse>
      <!-- NOTE: MoreDetail End-->

      <b-button class="mt-3" block @click="$bvModal.hide('modal-1')">Close</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { base_url } from '../../../const'
import MapServices from '../../../core/services/maps'
import scMapDetail from './sc-map-detail.vue'

export default {
  components: { scMapDetail },
  name: 'Modal',
  props: ['showPropsModal', 'eventDetail'],
  data() {
    return {
      item: this.eventDetail,
      url: base_url,
      kmlData: {},
      center: [],
      userPhoto: '',
      coverPhoto: '',
    }
  },
  async mounted() {
    const map = this.eventDetail.map
    this.photo()
    if (map) {
      if (map.fileName) {
        const mapDetail = (await MapServices.getMapDetail(map.fileName)).data
        this.kmlData = mapDetail.kmlData.features[0].geometry.coordinates
        this.center = mapDetail.kmlData.features[1].geometry.coordinates
      }
    }
  },
  methods: {
    photo() {
      this.coverPhoto = `${base_url}/uploads/default/${this.eventDetail.cover.default}`
      this.userPhoto = `${base_url}/uploads/default/${this.eventDetail.createdUser.photoUrl}`
    },
  },
}
</script>
