import ApiService from '../api.service'

const endPoint = '/events'

class EventServices {
  getAll() {
    return ApiService.get(endPoint)
  }

  getEventByStatus(statusName) {
    return ApiService.post(`${endPoint}/events-by-status`, { statusName })
  }

  eventDelete(data) {
    console.log(`data 111`, data)
    return ApiService.delete('/events/delete', { data })
  }
}
export default new EventServices()
